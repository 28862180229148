<template>
    <van-form @submit="onSubmit">
        <van-field
            v-model.trim="bankAccountName"
            name="bankAccountName"
            label="收款人"
            placeholder="收款人"
            :rules="[{ required: true, message: '请填写收款人' }]"
        />
        <van-field
            v-model.trim="bankAccountCode"
            type="tel"
            name="bankAccountCode"
            label="银行账户"
            placeholder="银行账户"
            :rules="[
                { required: true, message: '请填写银行账户' },
                { validator, message: '请填写正确的银行账户' },
            ]"
        />
        <div style="margin: 16px;">
            <van-button v-if="!isdisabled" color="#fe5e30" round block type="info" native-type="submit">提交</van-button>
            <van-button v-else color="#fe5e30" loading round block loading-type="spinner" type="info"
                        loading-text="提交中"/>
        </div>
    </van-form>
</template>

<script>
// 银行卡验证规则
const isBank = /^([1-9]{1})(\d{14}|\d{18})$/
import {setBank, getBank} from "../../assets/api/user";

export default {
    name: "Collection",
    data() {
        return {
            bankAccountName: '',
            bankAccountCode: '',
            isdisabled: false
        };
    },
    created() {
        this.getDetails()
    },
    methods: {
        // 详情
        getDetails() {
            getBank().then(res => {
                this.bankAccountName = res.data.bankAccountName
                this.bankAccountCode = res.data.bankAccountCode
            })
        },
        // 提交
        onSubmit(values) {
            this.isdisabled = true
            setBank(values).then(() => {
                this.$toast.success('提交成功')
                this.isdisabled = false
            }).catch(err => {
                this.$toast.fail(err)
                this.isdisabled = false
            })
        },
        // 银行账户校验
        validator(val) {
            return isBank.test(val.replace(/\s*/g, ""));
        }
    },
}
</script>